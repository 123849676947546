import React from 'react';

const Lore = () => {
    return (
        <>
            <p>
                Cypher Bisons are the first ever 1K collection on Palindrome Sats.
            </p>
            <p>
                1 Bison King in the Sub10K club
            </p>
            <p>
                999 Bisons on Palindromes
            </p>
            <p>
                Tribute to Cypherpunk OGs.
            </p>
            <p>
                No Utility, No Bullshit.
            </p>
        </>
    )
}

export default Lore